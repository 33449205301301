import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { device } from '../../providers/theme';

const Container = styled.section`
  margin: ${props => props.theme.space.sectionSpace} 0;
  padding: 22px;

  @media ${device.mobile} {
    padding: 0 12px;
  }

  &:hover h3 {
    text-decoration: underline;
    color: ${props => props.theme.colors.redWine};
  }
`;

const Image = styled(Img)`
  width: 100%;
  max-height: 400px;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  margin: 12px 0 0;
`;

const Author = styled.span`
  font-size: 14px;
  font-weight: 100;
  color: #888;
  margin: 12px 0;
`;

const Text = styled.p`
  font-weight: 100;
`;

function BlogSnippet({ image }) {
  return (
    <Container>
      <Link to="/blog/basisguide-til-vine">
        <Image fluid={image} />
        <Title>Guide til vine - en basisguide for begyndere</Title>
        <Text>
          Denne vinguide for begyndere vil give dig en grundlæggende basisviden
          om vine, og du vil få svar på mange spørgsmål såsom: hvordan opbevarer
          og serverer man bedst forskellige vine? og hvilken vin er bedst til
          maden?
        </Text>
      </Link>
    </Container>
  );
}

export default BlogSnippet;
