import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/layout';
import InfoCards from '../components/InfoCard';
import InfoContainer from '../components/InfoContainer';
import { FaShoppingBasket, FaWineGlass, FaHandshake } from 'react-icons/fa';
import BlogSnippet from '../components/BlogSnippet';
import Product from '../components/Product';
import { popularWines } from '../utils/popularWines';
const Title = styled.h1`
  font-size: 40px;
  padding: 0 32px;
`;

const Intro = styled.h2`
  font-size: 40px;
  padding: 0 32px;
`;

const Row = styled.div`
  background: ${props => props.theme.backgroundColors.main};
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 32px;
  margin-top: 32px;
`;

const Text = styled.p`
  display: block;
  font-size: 20px;
  margin: 0;
  padding: 0 32px;
  margin-bottom: 100px;
  line-height: 1.8;
`;

const SubText = styled.p`
  margin: 0;
  line-height: 1.8;
`;

const IndexPage = ({ data }) => {
  const highlightedBlogPost = data.allMarkdownRemark.edges.filter(e =>
    e?.node?.frontmatter?.image?.childImageSharp?.fluid?.src?.match(
      'basis-wine-guide'
    )
  );

  return (
    <React.Fragment>
      <Layout title="Find vine du søger. Se udvalg og tilbud fra vin butikker">
        <Title>Populære vine</Title>
        <Row>
          {popularWines.map(pro => (
            <Product
              title={pro.title}
              description={pro.description}
              price={pro.price}
              image={pro.image}
              path={pro.path}
            />
          ))}
        </Row>
        <Intro>De bedste vine, fra de bedste forhandlere.</Intro>
        <Text>
          Vinkammeret er ikke en vinbutik. Vinkammeret er samlingspunktet af
          vine fra landets bedste forhandlere.
          <br />
          Vores vision er, at du finder vinen du leder efter, her på siden.
          Udover et kæmpe udvalg fra landets forhandlere,
          <br />
          kan du samtidig læse om vores egne og andres oplevelser. Du kan
          samtidig anbefale en vin blot ved et klik, <br />
          og se hvilke vine der har fået flest anbefalinger fra andre.
          <br />
          God fornøjelse!
        </Text>
        <InfoContainer
          maxWidth={820}
          header
          title="Del dine anbefalinger af vin"
          text="På Vinkammeret kan du anbefale vinen, og folk kan på den måde se den samlede score på hver vin og vise, hvilke vin der er de mest anbefalede lige nu. Samtidig har du muligheden for at sortere efter populariteten for hver vintype."
        />
        <BlogSnippet
          image={
            highlightedBlogPost[0].node.frontmatter.image.childImageSharp.fluid
          }
        />
        <InfoCards
          firstColumn={{
            icon: <FaShoppingBasket size={42} />,
            title: 'Køb vin online',
            text:
              'På vinkammeret.dk finder du et stort udvalg af rødvine, hvidvine, rosévine, champagne, mousserende vine, og dessertvine, i høj kvalitet. Vinkammeret henvender sig til dig som sætter pris på et god vin, som samtidigt er nemt at finde og bestille hjem. '
          }}
          secondColumn={{
            icon: <FaWineGlass size={42} />,
            title: 'Det store udvalg',
            text:
              'Med hundredvis af vin samlet i samarbejde med forskellige forhandlere, har du en stor chance for at finde den rigtige vin du ønsker. Med en kort beskrivelse af hver vin forsøger vi at give dig en hurtig fornemmelse af, om vinen er noget for dig'
          }}
          thirdColumn={{
            icon: <FaHandshake size={42} />,
            title: 'Partnere',
            text:
              'Vinkammeret er til fri benyttelse for alle. Vi er en sammenligningstjeneste og ejer derfor ikke det store udvalg af diverse vin på siden, men samarbejder med flere af landets vinforhandlere, for at gøre udvalget stort og variende for dig som bruger'
          }}
        />
        <div>
          <Intro>
            Vær med til at dele vinanmelderser i {new Date().getFullYear()}
          </Intro>
          <Text>
            <SubText>
              Del dine oplevelser med andre vinentusiaster her på Vinkammeret.
            </SubText>
            <SubText>
              Ved hver eneste vin, uanset om det er rød, hvid, mousserende eller
              andet, har du mulighed for at dele din vinoplevelse.
            </SubText>
            <SubText>
              Alt du skal gøre er blot at logge ind, og du har nu adgang til at
              smide kommentaren, inde på den enkelte vin.
            </SubText>
            <SubText>
              Udover at skrive, kan du også anbefale vin blot med et enkelt
              klik, ved at klikke på anbefal knapper. Dette kræver ikke engang
              login!
            </SubText>
            <SubText>
              I øvrigt, kan du se hvilke vin der har den højeste rangering
              indenfor kategorien. Dette bliver sorteret efter flest
              anbefalinger.
            </SubText>
            <SubText>
              I øvrigt, kan du se hvilke vin der har den højeste rangering
              indenfor kategorien. Dette bliver sorteret efter flest
              anbefalinger.
            </SubText>
            <SubText>
              Skriv i kommentaren om du kan anbefale den som værende en god
              billig vin, en eksklusiv vin, eller om vinen anbefales til en bøf.
              Det er helt op til dig.
            </SubText>
            <SubText>
              Har du måske fundet den bedste rødvin til prisen, tænker du? Det
              er vi mange, der er nysgerrige efter at vide, hvilken vin du
              tænker! Har du en fransk vin anbefaling? Det kan være du har en
              oplevelse med en burgund vin, du bliver nød til at dele?
            </SubText>
            <SubText style={{ marginBottom: 42 }}>
              Vi håber du vil dele med os, her på Vinkammeret
            </SubText>
          </Text>
        </div>
      </Layout>
    </React.Fragment>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
