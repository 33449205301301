export const popularWines = [
  {
    title: 'Matilda Nieves 2020',
    description:
      'Matilde Nieves er en spændende og tildels funky sag, der på en måde kan minde om en blanding af stor Beaujolais, Pinot Noir og kølig Nord-Rhône.',
    price: '109,95',
    image:
      'https://cdn.confect.io/97/result/2023-02-27/c12b638e2d5e4b7399d413ba6733068b.jpg?flow=5202',
    path: 'https://www.vinkammeret.dk/roedvin/matilda-nieves-2020'
  },
  {
    title: 'Prosecco Blue Millesimato',
    description:
      "Val d'Oca Millesimato Blue er en herlig, sprød mousserende vin, der med dens charmerende florale duft og liflige bobler vil gå rent ind hos flertallet.",
    price: '79,95',
    image:
      'https://cdn.confect.io/97/result/2023-04-21/64a8a3e16c024167b979c1a0b60b46a4.jpg?flow=5202',
    path: 'https://www.vinkammeret.dk/mousserende/prosecco-blue-millesimato'
  },
  {
    title: 'Massaro Norma Valpolicella Ripasso Classico Superiore 2021',
    description:
      'Massaro Norma Valpolicella Ripasso Classico Superiore 2021 er en udsøgt vin, der stammer fra Valpolicella-regionen i det nordøstlige Italien. Denne vin er et klassisk eksempel på Ripasso-teknikken, hvor vinen får ekstra dybde og kompleksitet ved at gennemgå en anden gæring på skallerne fra Amarone-vin. Massaro Norma Valpolicella Ripasso Classico Superiore 2021 er kendt for sin fyldige smag og velafbalancerede struktur, som gør den til en favorit blandt vinelskere.',
    price: '169',
    image: 'https://buusvine.dk/wp-content/uploads/2020/09/Untitled-1.png',
    path:
      'https://vinkammeret.dk/roedvin/massaro-norma-valpolicella-ripasso-classico-superiore-2021'
  },
  {
    title: 'Clos De May',
    description:
      'Lækker fransk vin med druer fra Cabernet Sauvignon, Merlot. Årgang 2019',
    price: '219',
    image:
      'https://cdn.shopify.com/s/files/1/0654/2734/1560/products/clos-de-may-haut-medoc-2019-410920.jpg?v=1691574749',
    path: 'https://vinkammeret.dk/roedvin/clos-de-may-haut-m%C3%A9doc-2019'
  },
  {
    title: 'Liberated Pinot Noir 2020',
    description:
      'Liberated Pinot Noir har den sarte drues klassiske lyse, transparente farve og en yderst charmerende Pinot-næse med kirsebær, jordbær, skovbund og sekundære vaniljetoner.',
    price: '109,95',
    image:
      'https://cdn.confect.io/97/result/2023-11-27/4af9ffe3529f4930a17144c5ea67d5be.jpg?flow=5202',
    path: 'https://vinkammeret.dk/roedvin/liberated-pinot-noir-2020'
  },
  {
    title: 'Domaine Les Chaumes Jean Jacques Bardin Sancerre 2023',
    description:
      'Domaine Les Chaumes Jean Jacques Bardin Sancerre 2023 Denne lysegule vin har en intens duft af citrus, blomster og krydderier. Smagen er frisk med noter af citrusfrugt, krydderurter, nødder og et let mineralsk præg.',
    price: '159',
    image:
      'https://cdn.shopify.com/s/files/1/0816/5435/0170/files/domaine-les-chaumes-jean-jacques-bardin-sancerre-2023-333040.jpg?v=1714503180',
    path:
      'https://vinkammeret.dk/hvidvin/domaine-les-chaumes-jean-jacques-bardin-sancerre-2023'
  },
  {
    title: 'Chateauneuf Du Pape VV 2018',
    description:
      'Janasse Vieilles Vignes 2018 er (som altid) en maskulin Châteauneuf-du-Pape med et langt liv foran sig. Vinen åbner med intense, nuancerede aromaer af brombær, hindbær, lakrids, oliven, garrique og skovbund.',
    price: '650',
    image:
      'https://cdn.confect.io/97/result/2023-05-11/ddd5b9574c414e529ad14e8c7e0cc870.jpg?flow=5202',
    path:
      'https://vinkammeret.dk/roedvin/ch%C3%A2teauneuf-du-pape-vieilles-vignes-2018'
  },
  {
    title: 'Murphy Goode Pinot Noir',
    description:
      'Årgang 2021 af den hyper-populære Murphy-Goode er en stor-charmerende udgave af en Pinot Noir, der med sin bløde og saftige stil rammer en virkelig bred smag.',
    price: '119,95',
    image:
      'https://cdn.confect.io/97/result/2022-12-21/3a816bc382d449479dd87091c0a06fbf.jpg?flow=5202',
    path: 'https://www.vinkammeret.dk/roedvin/murphy-goode-pinot-noir-2021/'
  }
];
